import React from 'react';
import '../../Estilos/Portafolio.css';
import logo from '../../Media/Gráfico porcentajes web corporativo (55).png'; // Asegúrate de usar la ruta correcta


export const Portafolio = () => { 
  return (
    <div className="portafolio-container">
     
      <div className="content">
        <h2>Usuario</h2>
        <div className="tabs">
          <span className="active-tab">Portafolio</span>
        
        </div>
        <div className="balance-circle">
          <p>Q0.00</p>
          <p>Balance total</p>
        </div>
        <div className="account-info">
          <h3>Mi cuenta</h3>
          <div className="info-item">
            <span>Total en activos</span>
            <span>Q0.00</span>
          </div>
          <div className="info-item">
            <span>Poder de compra</span>
            <span>Q0.00</span>
          </div>
          <div className="info-item">
            <span>Dinero en proceso</span>
            <span>Q0.00</span>
          </div>
          <div className="info-item">
            <span>Activos pendientes</span>
            <span>Q0.00</span>
          </div>
        </div>
      </div>
      <div className="footer">
        <span>Icon1</span>
        <span>Icon2</span>
        <span>Icon3</span>
        <span>Icon4</span>
        <span>Icon5</span>
      </div>
    </div>
  );
};
