import React, { useState } from 'react';
import '../../Estilos/barra_navegacion.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faComment, faCamera, faCog, faBriefcase, faEllipsis, faSchool } from '@fortawesome/free-solid-svg-icons';

export const Navigation = ({ activeIndex, onNavigate }) => {
  const menuItems = [
    { icon: faHome, text: 'Principal' },
    { icon: faBriefcase, text: 'Portafolio' },
    { icon: faUser, text: 'Perfil' },
    { icon: faSchool, text: 'Aprendizaje' },
    { icon: faEllipsis, text: 'Más' },
  ];

  return (
    <div className="navigation">
      <ul>
        {menuItems.map((item, index) => (
          <li
            key={index}
            className={`list ${activeIndex === index ? 'active' : ''}`}
            onClick={() => onNavigate(index)}
          >
            <a href="#">
              <span className="icon">
                <FontAwesomeIcon icon={item.icon} />
              </span>
              <span className="text">{item.text}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
