import React, { useState } from 'react';
import '../../Estilos/detalle.css';
import StockChart from '../../Componentes/StockChart ';

export const Detalle = ({imagen, valor, titulo}) => { 
  const [count, setCount] = useState(0);

  const increment = () => {
      setCount(count + 1);
  };

  const decrement = () => {
      if (count === 0){
        return;
      }
      setCount(count - 1);
  };
  return (
    <>
      <div className="pantalla">
          <img className='imagen-detalle' src={imagen} ></img>
          <div className="nombre-empresa">
            {titulo}
          </div>

          <div className="sub-detalle-empresa">
            
            <h1> Q. {valor} </h1>
            <p> Valor actual </p>
          </div> 

            <StockChart valor={valor}></StockChart>
          
           
          <div className="sub-detalle-compra-venta">
            <h2> Seccción de Compra Venta </h2>
          </div> 

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>

          <h3 style={{marginLeft: '4vw'}}>Acciones a comprar </h3>
          <div className="counter-container">

            <button onClick={decrement} className="counter-button">-</button>
            <h3 style={{fontWeight: '200', fontSize: '1.5em', marginInline: '1vw', textDecoration: 'underline', textUnderlineOffset: '5px'}}> {count} </h3>
            <button onClick={increment} className="counter-button">+</button>

          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginBlock: '2vh'}}>
        <button className="boton-compra"> Cerrar Compra por Q.{ Number((count * valor * 1.05).toFixed(2)) } </button>
        <button className="boton-venta">  Cerrar Venta por Q.{ Number((count * valor * 0.95).toFixed(2)) } </button>
        </div>
        
  

      </div>
      
    </>
  )
}
