import React from 'react';
import '../../Estilos/recomended_card.css';
import { motion } from "framer-motion"; 

export const RecomendedCard = ({ imageSrc, title, Value, redirigir }) => {
    const formatCurrency = (value) => {
      return new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(value);
    };

    return (
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
        className="recomended-tag"
        onClick={() => {redirigir(imageSrc, Value, title)}}
      >
        <div className="recomended-details-container">
          <p>Compra: {formatCurrency(Value * 1.05)}</p>
          <p>Venta: {formatCurrency(Value * 0.95)}</p>
        </div>
        <div className="recomended-image-container">
          <img src={imageSrc} alt={title} /> 
        </div>
        <div className="recomended-details-container">
          <h2>{title}</h2>
        </div>
      </motion.div>
    );
};
