import React from 'react'
import '../../Estilos/portafolio_tag.css'

export const PortafolioCard = ({ imageSrc, title, acciones, valor, redirigir }) => {
    const formatCurrency = (valor) => {
      return new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(valor);
    };
  
    return (
      <div className="portafolio-price-tag" onClick={() => {redirigir(imageSrc, valor, title)}} >
        <div className="portafolio-image-container">
          <img src={imageSrc} alt={title} /> 
        </div>
        <div className="portafolio-details-container">
          <h2>{title}</h2>
          <p style={{color: 'gray'}}>Acciones actuales: {(acciones)}</p>
          <p style={{color: 'green'}}>Capital invertido: {formatCurrency(valor)}</p>
        </div>
      </div>
    ); 
  };