import React from 'react'
import '../../Estilos/mas.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faGear, faMoon } from '@fortawesome/free-solid-svg-icons';

export const Mas = () => {
  return (
    <>
      <div className="subtitulo">
        <FontAwesomeIcon style={{marginInline: '3vw'}} icon={faGear}></FontAwesomeIcon>
        Más Opciones
      </div> 
      <div className="opcion-mas">
        <FontAwesomeIcon icon={faMoon} style={{marginInline: '3vw'}}></FontAwesomeIcon>
        Activar Modo Oscuro
      </div> 
      <div className="opcion-mas">
        <FontAwesomeIcon icon={faCircleXmark} style={{marginInline: '3vw'}}></FontAwesomeIcon>
        Salir
      </div>                   
    </>
  )
}
