import React from 'react';
import '../../Estilos/price_tag.css';
import { motion } from "framer-motion"; 

export const ActionCard = ({ imageSrc, title, Value, redirigir }) => {

    const formatCurrency = (value) => {
      return new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(value);
    };

    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1}}
        transition={{ duration: 0.5 }}
        whileHover={{ scale: 1.05 }}
      >
        <div className="price-tag" onClick={() => {redirigir(imageSrc, Value, title)}} >
          <motion.div className="image-container"
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
          >
            <img src={imageSrc} alt={title} /> 
          </motion.div>
          <div className="details-container">
            <motion.h2
              initial={{ x: -200, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.3 }}
              style={{ textAlign: 'left' }}
            >
              {title}
            </motion.h2>
            <motion.p
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              Compra: {formatCurrency(Value * 1.05)}
            </motion.p>
            <motion.p
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.7 }}
            >
              Venta: {formatCurrency(Value * 0.95)}
            </motion.p>
          </div>
        </div>
      </motion.div>
    );
};
