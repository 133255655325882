// src/components/VideoItem.js
import React from 'react';

const VideoItem = ({ imageUrl, title, description }) => {
  return (
    <div className="video-item">
      <img src={imageUrl} alt={title} className="video-image" />
      <div className="video-details">
        <h2 className="video-title">{title}</h2>
        <p className="video-description">{description}</p>
      </div>
    </div>
  );
};

export default VideoItem;
