import React from 'react';
import { PortafolioCard } from './PortafolioCard'
import '../../Estilos/main.css' 
import '../../Estilos/feed.css' 
import '../../Estilos/portafolio_tag.css'
import { Header } from '../../Componentes/BarraNavegacion/Header'

import LogoMalher       from '../../Media/MALHER.png';
import CEMPRO           from '../../Media/CEMPRO.png';
import DOLAR            from '../../Media/DOLAR.png';
import EURO             from '../../Media/EURO.png';
import BONOSTESORO      from '../../Media/BONOSTESORO.png';
import TIGO             from '../../Media/TIGO.png';
import CLARO            from '../../Media/CLARO.png';
import WALMART          from '../../Media/WALMART.png';
import LICORESGUATEMALA from '../../Media/LICORESDEGUATEMALA.png';
import CEMACO           from '../../Media/CEMACO.png';
import EEGSA            from '../../Media/EEGSA.png';
import CMI              from '../../Media/CMI.png';
import KELLOGS          from '../../Media/KELLOGS.png';
import PRICESMART       from '../../Media/PRICESMART.png';
import UNILEVER         from '../../Media/UNILEVER.png';

export const Transferencia = ({redirigir}) => { 
  const EmpresasMercado = [
    { 'titulo' : 'TIGO', 'imagen': TIGO, 'acciones' : 5, 'valor': 750*5  },
    { 'titulo' : 'Bonos del Tesoro', 'imagen': BONOSTESORO, 'acciones' : 76, 'valor': 121*76   },
    { 'titulo' : 'CEMPRO', 'imagen': CEMPRO, 'acciones' : 12, 'valor': 1250*12   },
    { 'titulo' : 'DOLAR', 'imagen': DOLAR, 'acciones' : 150, 'valor': 7.50*150   },
    { 'titulo' : 'Kellog\'s', 'imagen': KELLOGS, 'acciones' : 2, 'valor': 754*2   }
  ]
  return (
    <> 
      <div className="pantalla">
        <div>
          <div className="subtitulo">
            Hola, Pedro
          </div>  
          <div className="portafolio-action-list">
            <p style={{margin: '2%'}}> Actualmente el saldo líquido de tu cartera de inversiones es de: </p>
            <h2 style={{textAlign: 'center'}}>
              Q 9,786.25
            </h2> 
            <div className="portafolio-button-container">
              <button className='portafolio-button'> Depositar a mi cartera </button>
              <button className='portafolio-button'> Retirar de mi cartera </button>
            </div>             
            
          </div>                  
          <div className="subtitulo">
            Mis Activos
          </div>          
          <div className="portafolio-action-list"> 
            {EmpresasMercado.map((x) => (
              <PortafolioCard            
                title={x.titulo}
                imageSrc={x.imagen}
                acciones={x.acciones}
                valor={x.valor}
                redirigir={redirigir}
              >
              </PortafolioCard>
            ))}            
          </div>
          <div className="saldo">
            Q. 10,753.07
          </div>
          <div className="saldo-texto">
            Balance total
          </div>
        </div>
      </div>

    </>
  )
}
