import React from 'react'
import { ActionCard } from './ActionCard'
import '../../Estilos/main.css' 
import '../../Estilos/feed.css'
import { RecomendedCard } from './RecomendedCard'
import { Header } from '../../Componentes/BarraNavegacion/Header'

import CEMPRO           from '../../Media/CEMPRO.png';
import DOLAR            from '../../Media/DOLAR.png';
import EURO             from '../../Media/EURO.png';
import BONOSTESORO      from '../../Media/BONOSTESORO.png';
import TIGO             from '../../Media/TIGO.png';
import CLARO            from '../../Media/CLARO.png';
import WALMART          from '../../Media/WALMART.png';
import LICORESGUATEMALA from '../../Media/LICORESDEGUATEMALA.png';
import CEMACO           from '../../Media/CEMACO.png';
import EEGSA            from '../../Media/EEGSA.png';
import CMI              from '../../Media/CMI.png';
import KELLOGS          from '../../Media/KELLOGS.png';
import PRICESMART       from '../../Media/PRICESMART.png';
import UNILEVER         from '../../Media/UNILEVER.png';


export const Feed = ({redirigir}) => { 
  const EmpresasMercado = [
    { 'titulo' : 'CEMPRO', 'imagen': CEMPRO, 'precio' : 1250  },
    { 'titulo' : 'DOLAR', 'imagen': DOLAR, 'precio' : 7.75  },
    { 'titulo' : 'EURO', 'imagen': EURO, 'precio' : 8.36  },
    { 'titulo' : 'Bonos del tesoro', 'imagen': BONOSTESORO, 'precio' : 121  },
    { 'titulo' : 'Tigo Guatemala', 'imagen': TIGO, 'precio' : 543  },
    { 'titulo' : 'Claro Guatemala', 'imagen': CLARO, 'precio' : 2324  },
    { 'titulo' : 'Walmart', 'imagen': WALMART, 'precio' : 1235  },
    { 'titulo' : 'Licores de Guatemala', 'imagen': LICORESGUATEMALA, 'precio' : 4678  },
    { 'titulo' : 'CEMACO', 'imagen': CEMACO, 'precio' : 232  },
  ]
  const EmpresasRecomendadas = [ 
    { 'titulo' : 'EEGSA', 'imagen': EEGSA, 'precio' : 928  },
    { 'titulo' : 'Kellogg\'s', 'imagen': KELLOGS, 'precio' : 754  },
    { 'titulo' : 'Price Smart', 'imagen': PRICESMART, 'precio' : 233  },
    { 'titulo' : 'UNILEVER', 'imagen': UNILEVER, 'precio' : 1453  }
  ]
  return (
    <> 
      <div className="pantalla">
        <div>
          <div className="saldo">
            Q. 10,753.07
          </div>
          <div className="saldo-texto">
            Balance total
          </div>
          <div className="subtitulo">
            Trending
          </div>
          <div className="recomended-list">
            {EmpresasRecomendadas.map((x) => (
              <RecomendedCard
                title={x.titulo}
                imageSrc={x.imagen}
                Value={x.precio}
                redirigir={redirigir}
              >
              </RecomendedCard>
            ))}            
          </div>
          <div className="subtitulo">
            Mercado
          </div>          
          <div className="action-list">
            {EmpresasMercado.map((x) => (
              <ActionCard            
                title={x.titulo}
                imageSrc={x.imagen}
                Value={x.precio}
                redirigir={redirigir}
              >
              </ActionCard>
            ))}            
          </div>

        </div>
      </div>

    </>
  )
}
