import React, { useRef, useEffect } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const StockChart = ({ valor }) => {
    const chartRef = useRef(null);

    const options = {
        animationEnabled: true,
        theme: "light2",
        axisX:{
            valueFormatString: "DD MMM",
            crosshair: {
                enabled: true,
                snapToDataPoint: true
            }
        },
        data: [{
            type: "area",
            xValueFormatString: "DD MMM",
            yValueFormatString: "Q##0.00",
            dataPoints: [
              { x: new Date("2024-07-25"), y: valor * 0.98},
              { x: new Date("2024-07-26"), y: valor * 0.99},
              { x: new Date("2024-07-27"), y: valor * 1.08},
              { x: new Date("2024-07-28"), y: valor * 1.05},
              { x: new Date("2024-07-29"), y: valor * 0.98},
              { x: new Date("2024-07-30"), y: valor * 0.89},
              { x: new Date("2024-07-31"), y: valor * 0.93},
              { x: new Date("2024-08-01"), y: valor * 0.97},
              { x: new Date("2024-08-02"), y: valor }
            ]
        }]
    }

    useEffect(() => {
        if (chartRef.current) {
            // Puedes acceder a las propiedades y métodos del gráfico aquí
            console.log(chartRef.current);
        }
    }, []);

    return (
        <div >
            <CanvasJSChart options={options} onRef={ref => chartRef.current = ref} />
        </div>
    );
}

export default StockChart;
