import { useState } from 'react';
import {Navigation} from './Componentes/BarraNavegacion/Navigation';
import './Estilos/main.css';
import { Aprendizaje } from './Pantallas/Aprendizaje/Aprendizaje';
import { Feed } from './Pantallas/Feed/Feed';
import { Detalle } from './Pantallas/Detalle/Detalle';
import { Portafolio } from './Pantallas/Portafolio/Portafolio';
import { Transferencia } from './Pantallas/Transferencia/Transferencia';
import { Mas } from './Pantallas/Mas/Mas';
import { Header } from './Componentes/BarraNavegacion/Header';

function App() {

  const [activeIndex, setActiveIndex] = useState(0);

  const handleNavigate = (index) => {
    setActiveIndex(index);
  };

  // Componentes para el detalle
  const [Imagen, setImagen] = useState(null);
  const [Valor, setValor] = useState(0);
  const [Titulo, setTitulo] = useState("");
  const VerDetalle = (imagen, valor, titulo) =>{
    setImagen(imagen);
    setValor(valor);
    setTitulo(titulo);
    console.log(imagen, valor, titulo)
    setActiveIndex(5);
  }

  const renderComponent = () => {
    switch (activeIndex) {
      case 0:
        return <Feed redirigir={VerDetalle}/>; 
      case 1:
        return <Portafolio  />;
      case 2:
        return <Transferencia redirigir={VerDetalle}/>; 
      case 3:
        return <Aprendizaje />;
      case 4:
        return <Mas />;
      case 5:
        return <Detalle imagen={Imagen} valor={Valor} titulo={Titulo} />;
      default:
        return <Feed />;
    }
  };

  return (
    <div className="app">
      <Navigation activeIndex={activeIndex} onNavigate={handleNavigate} />
      <div className="content">
        {renderComponent()}
      </div>
    </div>
  );
  

}

export default App;
