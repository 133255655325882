// src/pages/Aprendizaje.js
import React from 'react';
import VideoItem from '../Aprendizaje/VideoItem';
import '../../Estilos/Aprendizaje.css';

const videos = [
  {
    id: 1,
    title: 'Guía Completa para la Compra de Acciones en Bidex',
    description: 'En este tutorial, aprenderás paso a paso cómo comprar acciones en Bidex, una de las plataformas de inversión más populares. Desde la creación de tu cuenta hasta la ejecución de tu primera compra, te guiaremos a través de todo el proceso. Este tutorial es ideal tanto para principiantes como para inversores experimentados que buscan familiarizarse con la interfaz y las funcionalidades de Bidex.',
    imageUrl: 'https://th.bing.com/th/id/OIP.Rqx79lbZ4UY5lcCR2WYaIQHaCX?rs=1&pid=ImgDetMain',
    ethAmount: '0.041',
    timeLeft: '3 days left',
    avatarUrl: 'https://i.postimg.cc/SQBzNQf1/image-avatar.png',
    creator: 'Jules Wyvern'
  },
  {
    id: 2,
    title: 'Tipos de Inversiones Disponibles en Bidex',
    description: 'En este artículo, exploraremos los diversos tipos de inversiones que puedes realizar en Bidex, una plataforma líder en el mercado financiero. Ya sea que estés interesado en acciones, bonos, fondos mutuos u otros instrumentos financieros, Bidex ofrece una amplia gama de opciones para satisfacer tus necesidades de inversión.',
    imageUrl: 'https://th.bing.com/th/id/OIP.ZNtGVW0uco-wxlY0xjG1GQHaEK?rs=1&pid=ImgDetMain',
    ethAmount: '0.041',
    timeLeft: '3 days left',
    avatarUrl: 'https://i.postimg.cc/SQBzNQf1/image-avatar.png',
    creator: 'Jules Wyvern'
  },
  {
    id: 3,
    title: 'Cómo Diversificar tu Cartera de Inversiones en Bidex',
    description: 'En este tutorial, te mostraremos cómo diversificar tu cartera de inversiones en Bidex para reducir riesgos y maximizar rendimientos. Aprende a equilibrar tu inversión entre diferentes tipos de activos y sectores, aprovechando las variadas opciones que Bidex ofrece. Cubriremos estrategias básicas y avanzadas de diversificación para inversores de todos los niveles.',
    imageUrl: 'https://th.bing.com/th/id/OIP.-k_pfKCyWBsebVIeuCFkOwHaGr?rs=1&pid=ImgDetMain',
    ethAmount: '0.041',
    timeLeft: '3 days left',
    avatarUrl: 'https://i.postimg.cc/SQBzNQf1/image-avatar.png',
    creator: 'Jules Wyvern'
  },
  {
    id: 4,
    title: 'Cómo Analizar Acciones en Bidex para Tomar Decisiones de Inversión Informadas',
    description: 'Este artículo te enseñará cómo realizar un análisis exhaustivo de acciones en Bidex para tomar decisiones de inversión bien informadas. Aprenderás a utilizar herramientas de análisis técnico y fundamental disponibles en la plataforma para evaluar el potencial de crecimiento y los riesgos asociados con diferentes acciones.',
    imageUrl: 'https://th.bing.com/th/id/OIP.iS3SteOdbqeUpPhogoEaqQHaEK?rs=1&pid=ImgDetMain',
    ethAmount: '0.041',
    timeLeft: '3 days left',
    avatarUrl: 'https://i.postimg.cc/SQBzNQf1/image-avatar.png',
    creator: 'Jules Wyvern'
  },
  {
    id: 5,
    title: 'Estrategias Avanzadas de Inversión en Bidex: Opciones y Derivados',
    description: 'Este artículo está dirigido a inversores experimentados que desean explorar estrategias avanzadas utilizando opciones y derivados en Bidex. Descubre cómo estas herramientas financieras pueden ayudarte a proteger tus inversiones, especular sobre movimientos de precios y maximizar tus rendimientos.',
    imageUrl: 'https://th.bing.com/th/id/OIP.VlJ5Qo7cQIk3AUFSNkPhugAAAA?rs=1&pid=ImgDetMain',
    ethAmount: '0.041',
    timeLeft: '3 days left',
    avatarUrl: 'https://i.postimg.cc/SQBzNQf1/image-avatar.png',
    creator: 'Jules Wyvern'
  },
];

export const Aprendizaje = () => {
  return (
    <div className='pantalla'>
    <div className="aprendizaje-page">
      <div className="content">
        <h1>Aprendizaje</h1>
        <div className="videos-list">
          {videos.map((video) => (
            <VideoItem
              key={video.id}
              imageUrl={video.imageUrl}
              title={video.title}
              description={video.description}
            />
          ))}
        </div>
      </div>
    </div>
    </div>
  );
};
